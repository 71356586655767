@if (loggedUser) {
  <div class="opacity-dialog-content event-dialog-content" mat-dialog-content>
    <div (click)="close()" class="close-button">
      <i class="fal fa-times-circle"></i>
    </div>
    <div class="dialog-body">
      <div class="dialog-title">
        {{ "APP.REFERRAL_INVITE.INVITE" | translate }}
      </div>

      <app-qr-data-card
        [header]=getHeaderText()
        [footer]=env.platform
        [qrData]="loggedUser.getReferralLink()"
        [logo]="env.logoAsset"
        [qrLogo]="
          env.platformShort === 'partypage'
            ? './assets/pp_logo_qr.png'
            : './assets/ep_logo_qr.png'
        "
        [data]="loggedUser.referralCode"
        [loggedUser]="loggedUser"
        [isBackSide]="isReferredUsersView"
        [isReferral]='true'
        [users]='referredUsers'
      ></app-qr-data-card>

      <div class="referrals-info">
        @if (referredUsers && referredUsers.length > 0) {
          @if (isReferredUsersView) {
            <span>{{ "APP.REFERRAL_INVITE.INVITE_MORE" | translate }}</span
            >&nbsp;<span class="text-link" (click)="setReferredUsersView(false)"
              >{{ "APP.REFERRAL_INVITE.VIEW_QR_CODE" | translate }}.</span
            >
          } @else {
            <span>{{
              "APP.REFERRAL_INVITE.REFFERED_USERS_INFO"
                | translate: { numReferrals: referredUsers.length }
            }}</span
            >&nbsp;<span class="text-link" (click)="setReferredUsersView(true)"
              >{{ "APP.REFERRAL_INVITE.VIEW_REFERRALS" | translate }}.</span
            >
          }
        } @else {
          <span>{{ "APP.REFERRAL_INVITE.NO_REFERRALS" | translate }}</span>
        }
      </div>
    </div>
    <div mat-dialog-actions class="dialog-actions">
      <button class="btn btn-primary-party-page" (click)="shareInvite()">
        {{ "APP.REFERRAL_INVITE.SHARE_INVITE" | translate }}
      </button>
      <button class="btn btn-primary-outline" (click)="copyCode()">
        {{ "APP.REFERRAL_INVITE.COPY_INVITE_CODE" | translate }}
      </button>
    </div>
  </div>
}
